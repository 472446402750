.navBody {
  display: flex;
  flex-direction: column;
  border-bottom-width: 2px;
  border-bottom-color: #292929;
}
.navbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2% 0;
  color: #ffffff;
}
.Bitcoin {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.Bitcoin h1 {
  padding-right: 3px;
  padding-top: 5px;
  font-weight: 700;
  font-size: 1.4rem;
}
.navRight {
  display: flex;
  width: 70%;
  justify-content: right;
  align-items: center;
  direction: rtl;
}
/* ul {
  width: 37%;
  display: flex;
  direction: ltr;
  justify-content: space-between;
  align-items: center;
}
ul li {
  color: #a9a9a9;
  font-size: 0.9rem;
  display: flex;
  cursor: pointer;
  align-items: center;
}
ul li:hover {
  color: #ffffff;
} */
.navbar button {
  display: flex;
  color: #ffffff;
  background-color: #ffffff43;
  align-items: center;
  font-size: 0.8rem;
  padding: 10px 20px;
  border-radius: 18px;
  margin-left: 5%;
  cursor: pointer;
  direction: ltr;
}
.new {
  color: azure;
  border-radius: 7px;
  font-size: 0.6rem;
  margin: 0 7px;
  padding: 2px 7px;
  background-color: #7e42ff;
}
.menuItemsMobile span {
  color: azure;
  border-radius: 7px;
  font-size: 0.6rem;
  margin: 0 7px;
  padding: 2px 7px;
  background-color: #7e42ff;
}
.user {
  margin-right: 8px;
}
.headerLoginButton {
  display: flex;
  color: #ffffff;
  background-color: #ffffff43;
  align-items: center;
  font-size: 0.8rem;
  padding: 10px 20px;
  border-radius: 18px;
  cursor: pointer;
  direction: rtl;
  text-align: center;
}
.menuItemsPc {
  display: flex;
}
.menuItemsMobile {
  display: none;
}
.name {
  margin: 20px;
  color: white;
  font-weight: 700;
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
  cursor: pointer;
  align-items: center;
}
/* @media screen and (max-width: 1240px) {
  ul {
    width: 45%;
  }
} */
@media screen and (max-width: 1050px) {
  .navbar button {
    padding: 6px 17px;
  }
  /* ul {
    width: 55%;
  } */
  .user {
    width: 13px;
  }
  /* ul li {
    font-size: 0.8rem;
  } */
}

@media screen and (max-width: 900px) {
  /* ul {
    width: 64%;
  } */
}

@media screen and (max-width: 700px) {
  .navbar button {
    padding: 5px 13px;
  }
  .user {
    width: 10px;
  }
  .menuItemsPc {
    display: none;
  }
  .menuItemsMobile {
    display: flex;
  }

  .Bitcoin h1 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 580px) {
  .navbar button {
    padding: 2px 10px;
  }
  .user {
    width: 9px;
  }

  /* ul {
    width: 70%;
  } */
  .Bitcoin h1 {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .Bitcoinicon {
    width: 15px;
  }

  /* ul {
    padding: 10px;
    width: 90%;
  } */

  .navRight {
    flex-direction: column;
    align-items: end;
    direction: ltr;
  }
}
