body {
  background-color: #1c1c1c;
}

.continer {
  max-width: 2000px;
  color: #ffffff;
  display: flex;
  height: 100vh;
  justify-content: center;
}
.continer2 {
  display: none;
  max-width: 2000px;
  color: #ffffff;
  height: 100vh;
  justify-content: center;
}

.left img {
  height: 100vh;
  width: 100%;
}

.left {
  width: 70%;
}

.right {
  width: 40%;
  display: flex;
  /* align-content: center; */
  justify-content: center;
}

.form {
  display: flex;
  justify-content: center;
  direction: rtl;
  width: 60%;
  flex-direction: column;
}

.form h1 {
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 800;
}
.form h6 {
  font-size: 0.7rem;
  margin: 2%;
  color: #ffffff65;
}

.form input {
  padding: 3% 3%;
  border-radius: 5px;
  background-color: rgba(56, 56, 56, 0.849);
  /* border: 0.05px solid white; */
  font-size: 0.8rem;
  border: none;
  outline: none;
}
.form button {
  background-color: #7e42ff;
  padding: 2% 3%;
  border-radius: 5px;
  margin: 4% 0;
  cursor: pointer;
  font-weight: 800;
}
.left {
  display: none;
}
@media screen and (max-width: 1025px) {
  .left {
    display: none;
  }
  .right {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .form {
    width: 80%;
  }
}
