.content {
    width: 100%;
    display: flex;
    max-width: 2000px;

}

.imgbox {
    width: 30%;
    height: 100vh;
    position: fixed;
}

.imgbox img {
    height: 100vh;
    width: 100%;
}

.textbar {
    width: 70%;
    margin-left: 30%;
    direction: rtl;
    height: 100vh;
}

.bg {
    padding: 2% 5%;
    align-items: center;
    display: flex;
    color: #ffffff;
    justify-content: space-between;
}

.TapL h6 {
    font-size: 0.7rem;
    margin: 5px 0;
    color: #ffffff8d;
}

.TapLnumber {
    align-items: center;
    display: flex;
}

.LogoutCurve {
    width: 18px;
    cursor: pointer;
    margin-right: 3px;
}

.TapR h6 {
    font-size: 0.8rem;
    color: #ffffff8d;
    margin: 3px 0;
}

.red {
    width: 80%;
    margin: auto;
    height: 50px;
    background-color: red;
}
.textbar h1{
    text-align: center;
    color: #ffffff;
    margin: 3% auto;
    font-size: 1.2rem;
}
.main{
    display: flex;
    flex-direction: row;
    direction: ltr;
    align-items: center;
    margin: 0 10%;
    justify-content: space-around;
}
.main img{
    cursor: pointer;
   width: 23vw;
}
.button{
    padding-bottom: 1%;
}
.button button {
    background-color: #7e42ff;
    width: 80%;
    font-size: 1.2rem;
    cursor: pointer;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    border-radius: 5px;
    margin:5% auto;
}


@media screen and (max-width : 1023px) {
    .imgbox img {
        display: none;
    }

    .textbar {
        margin-left: 0%;
        margin: auto;
        width: 100%;
    }

    .button button {
        margin-bottom: 5%;
    }
    .textbarTap {
        background-image: url(../../assetes/img/login.png);
        background-repeat: no-repeat;
        background-size: cover;
        height: 70vh;
        padding: 0;
    }
    .bg{
        align-items: end;
        padding: 2% 5%;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
       background-image: linear-gradient(to top, rgba(28, 28, 28, 1) 0%, rgba(28, 28, 28, 0) 100%)

    }

    .red {
        margin:7% auto;
    }
    .main{
        flex-direction: column;

    }
    .main img{
       width: 50vw;
    }
    .mainL{
        margin-bottom: 10%;
    }
}


@media screen and (max-width : 500px){
    .red{
      width: 90%;
    }
    .textbarTap{
        height: 60vh;
    }

.TapL h6 {
    font-size: 0.6rem
}

.TapLnumber h2 {
    font-size: 0.8rem;
}
.TapR h6 {
    font-size: 0.6rem;
}
.TapR h4 {
    font-size: 0.8rem;
}
  .button button{
    width: 90%;
    font-size: 0.7rem;
    margin:10% auto;
   
  }
  .textbar{
    margin-bottom: 200px;
  }
 
  
}