.content {
    width: 100%;
    display: flex;
    max-width: 2000px;

}

.imgbox {
    width: 30%;
    height: 100vh;
    position: fixed;
}

.imgbox img {
    height: 100vh;
    width: 100%;
}

.textbar {
    width: 70%;
    margin-left: 30%;
    direction: rtl;
    height: 100vh;
}

.bg {
    padding: 2% 5%;
    align-items: center;
    display: flex;
    color: #ffffff;
    justify-content: space-between;
}

.TapL h6 {
    font-size: 0.7rem;
    margin: 5px 0;
    color: #ffffff8d;
}

.TapLnumber {
    align-items: center;
    display: flex;
}

.LogoutCurve {
    width: 18px;
    cursor: pointer;
    margin-right: 3px;
}

.TapR h6 {
    font-size: 0.8rem;
    color: #ffffff8d;
    margin: 3px 0;
}

.red {
    width: 80%;
    margin: auto;
    height: 50px;
    background-color: red;
}

.box {
    display: grid;
    width: 80%;
    margin: 3% auto;
    color: #ffffff;
    row-gap: 10%;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: 1fr 1fr; */
}

.input {
    display: flex;
    font-size: 1.1rem;
}

input[type="radio"] {
    margin-left: 10px;
    background-color: #7e42ff;
    color: #7e42ff;
}

.box ul {
    width: 80%;
    display: flex;
    direction: rtl;
    align-items: start;
    flex-direction: column;
}

.boxbut {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    flex-direction: column;
}

textarea {
    width: 100%;
    padding: 10px;
    color: #ffffff8d;
    margin: 3% 0;
    border-radius: 5px;
    background-color: #ffffff1f;
    border: 0.2px solid #ffffff40;
}

.boxbut button {
    background-color: #7e42ff;
    width: 100%;
    margin-top: -10px;
    font-size: 1.2rem;
    cursor: pointer;
    color: #ffffff;
    padding: 5px 0;
    border-radius: 5px;
}
.button{
    width: 100%;
}
@media screen and (max-width : 1300px) {
    .input {
        font-size: 0.9rem;
    }

   .box ul li {
        font-size: 0.8rem;
    }

    textarea {
        font-size: 0.8rem;
    }

    .boxbut button {
        font-size: 0.9rem;
    }

}

@media screen and (max-width : 1023px) {
    .imgbox img {
        display: none;
    }

    .textbar {
        margin-left: 0%;
        margin: auto;
        width: 100%;
    }

    .box {
        grid-template-columns: 1fr;
        width: 80%;
        padding-bottom: 20vh;
    }

    .boxbut button {
        margin-bottom: 5%;
    }
    .textbarTap {
        background-image: url(../../assetes/img/login.png);
        background-repeat: no-repeat;
        background-size: cover;
        height: 70vh;
        padding: 0;
    }
    .bg{
        align-items: end;
        padding: 2% 5%;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
       background-image: linear-gradient(to top, rgba(28, 28, 28, 1) 0%, rgba(28, 28, 28, 0) 100%)

    }

    .red {
        margin:7% auto;
    }
}

@media screen and (max-width : 500px){
    .red{
      width: 90%;
    }
    textarea{
        margin: 7% 0;
    }
    
.TapL h6 {
    font-size: 0.6rem
}

.TapLnumber h2 {
    font-size: 0.8rem;
}

.LogoutCurve {
    width: 13px;
}

.TapR h6 {
    font-size: 0.6rem;
}
.TapR h4 {
    font-size: 0.8rem;
}
.box{
    row-gap: 5%;
    width: 90%;
    padding-bottom: 10vh;
}
.box ul{
    width: 90%;
}
.box li{
    padding-right: 22px;
}
  .boxbut{
    width: 90%;
  }
  textarea{
    font-size: 0.6rem;
  }
  
}