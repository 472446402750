@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #010101;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assetes/font/fa/DanaFaNum-Thin.woff");
  font-weight: 100;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assetes/font/fa/DanaFaNum-Light.woff");
  font-weight: 300;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assetes/font/fa/DanaFaNum-Regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assetes/font/fa/DanaFaNum-Medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assetes/font/fa/DanaFaNum-Bold.woff");
  font-weight: 700;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assetes/font/fa/DanaFaNum-DemiBold.woff");
  font-weight: 800;
}
@font-face {
  font-family: "Dana";
  src: url("../src/assetes/font/fa/DanaFaNum-Black.woff");
  font-weight: 900;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assetes/font/en/Gilroy-Thin.woff");
  font-weight: 100;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assetes/font/en/Gilroy-Light.woff");
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assetes/font/en/Gilroy-Regular.woff");
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assetes/font/en/Gilroy-Medium.woff");
  font-weight: 500;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assetes/font/en/Gilroy-Bold.woff");
  font-weight: 700;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assetes/font/en/Gilroy-ExtraBold.woff");
  font-weight: 800;
}
@font-face {
  font-family: "Gilroy";
  src: url("../src/assetes/font/en/Gilroy-Black.woff");
  font-weight: 900;
}

.Calendar {
  background: #1c1c1c !important;
  --cl-color-black: #444444;
  --cl-color-disabled: #a9a9a9 !important;
  --cl-color-error: #ff2929;
  font-family: "Dana" !important;
}
.Calendar__day {
  color: #a9a9a9 !important;
}
.Calendar__day:hover:not(.-selected) {
  color: #fff !important;
  background-color: #505050 !important;
}
.Calendar__day.-weekend:not(.-selected):not(.-blank):not(.-selectedStart):not(
    .-selectedEnd
  ):not(.-selectedBetween) {
  color: #ff2929 !important;
}
.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  color: #fff !important;
}
.Calendar > :not(.Calendar__footer) button {
  color: #fff;
}
.Calendar > :not(.Calendar__footer) button:hover {
  color: #fff !important;
  background-color: #505050 !important;
}
.Calendar.-rtl .Calendar__monthArrowWrapper.-right {
  fill: green !important;
}
.Calendar__monthSelector,
.Calendar__yearSelector {
  background: #1c1c1c !important;
  width: 100%;
}
.Calendar__yearSelectorWrapper::after {
  background-image: linear-gradient(
    to bottom,
    #1c1c1c,
    #1c1c1c 10%,
    rgba(245, 245, 245, 0)
  ) !important;
}
.Calendar__yearSelectorWrapper::before {
  background-image: linear-gradient(
    to top,
    #1c1c1c,
    #1c1c1c 10%,
    rgba(245, 245, 245, 0)
  ) !important;
}
.Calendar__weekDays {
  font-weight: bold;
}
.Calendar__monthArrow {
  filter: invert(100%) brightness(1000%) !important;
}
