* {
  font-family: "Dana" !important;
  max-width: 2000px;
}
.body {
  background-color: #010101;
}
.daniHead2 {
  display: none;
}

.header {
  width: 100%;
  overflow: hidden !important;
  background-color: #010101;
  position: relative;
}
.freeCourseButton {
  display: flex;
  color: #ffffff;
  align-items: center;
  font-size: 0.9rem;
  padding: 15px 25px;
  border-radius: 25px;
  font-weight: 500;
  cursor: pointer;
  background-image: linear-gradient(90deg, #32176a, #7e42ff);
  width: 190px;
}

.headRight {
  color: rgb(255, 255, 255);
}

.head {
  overflow: hidden;
  display: flex;
  width: 90%;
  max-width: 2000px;
  align-items: center;
  margin: auto;
  justify-content: space-around;
}

.headLeft img {
  width: 100%;
  height: 70%;
}

.headLeft {
  width: 42%;
}

.headRight {
  direction: rtl;
  width: 50%;
  z-index: 100;
}

.headRight h1 {
  font-weight: 700;
  font-size: 3.5rem;
}

.headRight h3 {
  z-index: 100;
  font-weight: 500;
  font-size: 1rem;
  color: rgba(169, 169, 169, 1);
  width: 75%;
  padding: 5% 0;
}

.headRight span {
  color: rgb(255, 255, 255);
}

.headRight button {
  z-index: 10;
  display: flex;
  color: #ffffff;
  align-items: center;
  font-size: 0.9rem;
  padding: 15px 25px;
  border-radius: 25px;
  font-weight: 500;
  cursor: pointer;
  background-image: linear-gradient(90deg, #32176a, #7e42ff);
}

.arrow {
  margin-right: 5px;
}

.period {
  width: 100%;
  padding-bottom: 5%;
  background-color: #000000;
  padding: 5% 0;
}

.periodimg {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto;
  cursor: pointer;
}

.periodText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4% 8%;
  padding-bottom: 0;
  color: #ffffff;
}

.periodText h1 {
  font-size: 2rem;
  font-weight: 700;
}

.periodTextL a {
  display: flex;
  font-weight: 500;
  font-size: 1.5rem;
  align-items: center;
}

.VideoVertical {
  margin-right: 5px;
}

.periodBox {
  width: 90%;
  max-width: 2000px;
  height: 90vh;
  background-color: #16092f;
  margin: auto;
  border-radius: 10px;
}

.comments {
  background-color: #010101;
  color: #ffffff;
  font-weight: bold;
  padding-bottom: 5%;
}

.comments h1 {
  font-size: 3rem;
  text-align: center;
}

.telegram {
  background-color: #010101;
  width: 100%;
  display: flex;
  margin: 5% 0;
  justify-content: center;
}

.telegram img {
  width: 90%;
  max-width: 1000px;
  display: flex;
  margin: auto;
  margin-bottom: 5%;
  text-align: center;
  justify-content: center;
}

/* body {
    background-color: #010101;
} */

.footerTap {
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  justify-content: space-around;
  margin-bottom: 1.5%;
}

.footerTapL {
  font-weight: bold;
  color: #ffffffa3;
  font-size: 0.8rem;
}

.footerTapL span {
  color: azure;
  border-radius: 7px;
  font-size: 0.5rem;
  margin: 0 7px;
  font-family: "Dana";
  padding: 2px 7px;
  /* width: 50%; */
  background-color: #7e42ff;
}

.footerTapR {
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
}

.footerpad {
  padding: 0 5px;
  cursor: pointer;
}

.footerBt {
  color: #ffffff;
}

.footefull {
  background-color: #7e42ff;
  text-align: center;
  font-weight: bold;
  padding: 1.5% 0;
  font-size: 0.7rem;
}

.footeDev {
  display: inline-block;
  background-color: #7e42ff;
  border-radius: 10px 10px 0 0;
  padding: 8px 15px;
  margin-bottom: -3px;
  font-size: 0.8rem;
  margin-left: 5%;
}
.footerTapL {
  width: 50%;
  display: flex;
}
.footerTapLBOX {
  margin: 0 2%;
  direction: rtl;
}
.container {
  overflow: hidden;
  white-space: nowrap;
  color: #ffffff;
}
.parent {
  animation: 5s example infinite linear;

  transition: width 2s;
  display: flex;
  padding: 5px 0;
}
.parent h1 {
  padding: 0 5px;
  font-size: 1.5rem;
}
@keyframes example {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
}

@media screen and (max-width: 1100px) {
  .headRight h1 {
    font-size: 2.8rem;
  }

  .headRight h3 {
    font-size: 0.8rem;
  }

  .headRight button {
    font-size: 0.7rem;
    padding: 10px 20px;
  }
}

@media screen and (max-width: 1025px) {
  .comments h1 {
    font-size: 1.5rem;
  }
  .periodTextL a {
    font-size: 0.8rem;
  }
  .VideoVertical {
    width: 15px;
  }
  .periodTextR h1 {
    font-size: 1rem;
  }
  .headRight {
    width: 80%;
  }

  .headRight h1 {
    padding-top: 10%;
    font-size: 3.4rem;
  }

  .headRight h3 {
    /* font-weight: 300; */
    font-size: 1rem;
    width: 100%;
  }

  .headRight button {
    align-items: center;
    font-size: 0.9rem;
    padding: 15px 25px;
    margin-bottom: 10%;
  }

  .daniHead {
    display: none;
  }

  .daniHead2 {
    display: flex;
    width: 100%;
    height: 50%;
    margin: auto;
  }

  .headLeft {
    width: 100%;
  }

  .footeDev {
    font-size: 0.5rem;
    padding: 5px 10px;
  }

  .footefull {
    font-size: 0.5rem;
    padding: 2%;
  }

  .footerTap {
    font-size: 0.5rem;
  }

  .icon {
    width: 13px;
  }

  .footerpad {
    padding: 0 3px;
  }

  .footerTapL {
    font-size: 0.6rem;
  }

  .head {
    flex-direction: column;
  }
}

@media screen and (max-width: 700px) {
  .comments h1 {
    font-size: 2rem;
  }
  .periodTextL a {
    font-size: 1rem;
  }
  .VideoVertical {
    width: 15px;
  }
  .periodTextR h1 {
    font-size: 1.3rem;
  }
  .footerTap {
    flex-direction: column;
  }

  .footerTapR {
    padding: 14px 0;
  }

  .footeDev {
    font-size: 0.6rem;
    padding: 5px 10px;
  }

  .headRight h1 {
    font-size: 2.5rem;
  }

  .headRight h3 {
    font-size: 1rem;
  }
  .headLeft img {
    width: 100%;
    height: 70%;
  }
  .headRight button {
    align-items: center;
    font-size: 0.6rem;
    padding: 7px 15px;
    margin-bottom: 10%;
  }
}

@media screen and (max-width: 550px) {
  .footerTapL {
    justify-content: center;

    width: 70%;
  }
  .footerTapLBOX {
    margin: 0 2%;
  }

  .comments h1 {
    font-size: 1.5rem;
  }
  .periodTextL a {
    font-size: 0.8rem;
  }
  .VideoVertical {
    width: 15px;
  }
  .periodTextR h1 {
    font-size: 1rem;
  }
  .headRight {
    width: 80%;
  }

  .headRight h1 {
    padding-top: 10%;
    font-size: 1.7rem;
  }

  .headRight h3 {
    font-weight: 300;
    font-size: 0.7rem;
    width: 100%;
  }

  .headRight button {
    align-items: center;
    font-size: 0.6rem;
    padding: 7px 15px;
    margin-bottom: 10%;
  }

  .daniHead {
    display: none;
  }

  .daniHead2 {
    display: flex;
    width: 100%;
    height: 50%;
    margin: auto;
  }

  .headLeft {
    width: 100%;
  }

  .footeDev {
    font-size: 0.5rem;
    padding: 5px 10px;
  }

  .footefull {
    font-size: 0.5rem;
    padding: 2%;
  }

  .footerTap {
    font-size: 0.5rem;
  }

  .icon {
    width: 13px;
  }

  .footerpad {
    padding: 0 3px;
  }

  .footerTapL {
    font-size: 0.6rem;
  }

  .head {
    flex-direction: column;
  }
}
